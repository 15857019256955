
// Libraries
import * as React from 'react'

// Components
import PaginationLink from './paginationLink'

class Pagination extends React.Component {
	render() {
		const {pagination, handlePaginationClick, hideCount, mobileStack} = this.props
		
		const firstPageLink = {
			label: '<<',
			url: pagination.first_page_url,
			active: false
		}

		const lastPageLink = {
			label: '>>',
			url: pagination.last_page_url,
			active: false
		}

		return (
			pagination && <div className={`pagination ${mobileStack && 'pagination--mobile-stack'}`}>
				<div className="column column--counts">
					{!hideCount &&
						<p><b>{pagination?.data?.length}</b> results of <b>{pagination?.total}</b></p>
					}
				</div>
				<div className="column column--links">
					<p>
						<PaginationLink currentPage={pagination.current_page} link={firstPageLink} handleClick={handlePaginationClick} />
						{pagination?.links?.map((link) => <PaginationLink currentPage={pagination.current_page} link={link} handleClick={handlePaginationClick} />)}
						<PaginationLink currentPage={pagination.current_page} link={lastPageLink} handleClick={handlePaginationClick} />
					</p>
				</div>
			</div>
		)
	}
}

export default Pagination

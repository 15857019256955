
// Libraries
import * as React from 'react'

// Components
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

class AdminTableHeader extends React.Component {
	render() {
		const {titleIcon, titleText, infoText} = this.props

		return <div className="admin-table__header">
			<div className="column column--title">
				<h1><FontAwesomeIcon icon={titleIcon} /> {titleText}</h1>
				{(infoText && infoText !== '') && <p>{infoText}</p>}
			</div>
			<div className="column column--search"></div>
		</div>
	}
}

export default AdminTableHeader


// Libraries
import React from 'react'

// Components
import {Link} from 'gatsby'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
	faUsers,
	faCalendarPlus,
	faMapMarkerAlt,
	faListAlt,
	faNewspaper,
	faPenSquare,
	faUserMusic,
	faFileExport,
	faCogs,
	faBullhorn,
	faPaperPlane,
	faSitemap
} from '@fortawesome/pro-solid-svg-icons'

const navItems = [
	{
		name: 'Dashboard',
		to: '/admin/dashboard',
		icon: faListAlt,
		active: true
	},
	{
		name: 'Users',
		to: '/admin/users',
		icon: faUsers,
		active: true
	},
	{
		name: 'Events',
		to: '/admin/events',
		icon: faCalendarPlus,
		active: true
	},
	{
		name: 'Artists',
		to: '/admin/artists',
		icon: faUserMusic,
		active: true
	},
	{
		name: 'Blog',
		to: '/admin/blog',
		icon: faNewspaper,
		active: true
	},
	{
		name: 'Venues',
		to: '/admin/venues',
		icon: faMapMarkerAlt,
		active: true
	},
	{
		name: 'Organisations',
		to: '/admin/organisations',
		icon: faSitemap,
		active: true
	},
	{
		name: 'Broadcasts',
		to: '/admin/broadcasts',
		icon: faBullhorn,
		active: true
	},
	{
		name: 'Site Content',
		to: '/admin/content-management',
		icon: faPenSquare,
		active: true
	},
	{
		name: 'Email Content',
		to: '/admin/transactional-emails',
		icon: faPaperPlane,
		active: true
	},
	{
		name: 'Tasks Queue',
		to: '/admin/background-tasks',
		icon: faCogs,
		active: true
	},
	{
		name: 'CSV Exports',
		to: '/admin/csv-exports',
		icon: faFileExport,
		active: true
	}
]

class PrimaryNavAdmin extends React.Component {
	render() {
		const isBrowser = typeof window !== "undefined"
		const locationPath = isBrowser ? window.location.pathname : ''

		return (
			<div className="primary-nav-admin">
				<ul className="primary-nav-admin__menu">
					{navItems.map((navItem) =>
						navItem.active && <li>
							<Link to={navItem.to} title={navItem.name} className={locationPath.startsWith(navItem.to) && 'active'}>
								<FontAwesomeIcon icon={navItem.icon} title={`${navItem.name} icon`} /> {navItem.name}
							</Link>
						</li>
					)}
				</ul>
			</div>
		)
	}
}

export default PrimaryNavAdmin

// Libraries
import * as React from 'react'

// Components
import PrimaryNavAdmin from './primaryNavAdmin'
import SecondaryNavAdmin from './secondaryNavAdmin'

const LayoutAsideAdmin = () => (
	<aside className="aside-admin">
		<PrimaryNavAdmin />
		<SecondaryNavAdmin />
	</aside>
)

export default LayoutAsideAdmin


// Libraries
import * as React from 'react'
import moment from "moment"
import Classnames from 'classnames'

// Context
import NotificationContext from '../../../contexts/notification'

// components
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faInfoCircle, faDebug} from '@fortawesome/pro-solid-svg-icons'
import ReactTooltip from 'react-tooltip'

class AdminBackgroundTasksTableRow extends React.Component {

	static contextType = NotificationContext

	render() {
		const {item, activeFilters} = this.props
		let classNames = Classnames([
			'admin-table__row'
		])

		if (item.data) {
			const customData = JSON.parse(item.data)
			if (customData.description) {
				item.description = customData.description
				delete customData.description
			}
			item.info = JSON.stringify(customData, null, '\t')
		}

		return <tr className={classNames}>
			<td className="admin-table__cell--name">{item.name.replace('App\\Jobs\\', '')}</td>
			<td className="admin-table__cell--description">{item.description || '-'}</td>
			<td className="admin-table__cell--info">{item.info
				? <>
					<FontAwesomeIcon icon={faInfoCircle} title="Task information icon" data-tip data-for={`tooltip-item-${item.id}-info`}/>
					<ReactTooltip id={`tooltip-item-${item.id}-info`} place="left" backgroundColor="#005EB8" textColor="#fff">
						<pre>{item.info}</pre>
					</ReactTooltip>
				</>
				: '-'
			}</td>
			{!['complete', 'failed'].includes(activeFilters?.scope) && <td>{item.started_at ? moment(item.started_at).format('DD/MM/YY HH:mm') : '-'}</td>}
			{['complete', 'failed'].includes(activeFilters?.scope) && <td>{item.finished_at ? moment(item.finished_at).format('DD/MM/YY HH:mm') : '-'}</td>}
			{['failed'].includes(activeFilters?.scope) && <td className="admin-table__cell--error">{item.exception
				? <>
					<FontAwesomeIcon icon={faDebug} title="Task error icon" data-tip data-for={`tooltip-item-${item.id}-error`}/>
					<ReactTooltip id={`tooltip-item-${item.id}-error`} place="left" backgroundColor="#005EB8" textColor="#fff">
						<span>{item.exception && item.exception.substring(0, 350) + '...'}</span>
					</ReactTooltip>
				</>
				: '-'
			}</td>}
		</tr>
	}
}

export default AdminBackgroundTasksTableRow

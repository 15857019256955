
/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

// Libraries
import * as React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

// Components
import Header from './header'
import LayoutAsideAdmin from './layoutAsideAdmin'
import Notification from './notification'

// Context
import NotificationContext from '../contexts/notification'

const LayoutAdmin = ({ className, children, hideSideNav, pageAdminForm }) => {
	const data = useStaticQuery(graphql`
    	query {
      		site {
        		siteMetadata {
          			title
        		}
      		}
    	}
  	`)

	return (
		<div className="admin-area">
			<Header siteTitle={data.site.siteMetadata?.title || `Title`} isAdmin hideSideNav={hideSideNav} pageAdminForm={pageAdminForm} />
			<section className="main-wrap main-wrap--aside-admin">
				{!hideSideNav && <LayoutAsideAdmin />}
				<main className={className}>
					<NotificationContext.Consumer>
						{value => (
							<>
								{value?.notification &&
									<Notification message={value?.notification?.message}
										type={value?.notification?.type}
										notificationId={value?.notification?.id}
										closing={value?.notification?.closing}
										expireNow={value?.expireNow}
									/>
								}
							</>
						)}
					</NotificationContext.Consumer>

					{children}
				</main>
			</section>
		</div>
	)
}

LayoutAdmin.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	hideSideNav: PropTypes.bool,
}

export default LayoutAdmin


// Services
import ApiService from './api'

// Initialise new instance of the API service
const apiService = new ApiService()

export default class BackgroundTasksService {
	async list(queryParams = {}) {
		return await apiService.get(`background-tasks`, {}, queryParams)
	}
}

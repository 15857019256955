
// Libraries
import * as React from 'react'
import Classnames from 'classnames'

class PaginationLink extends React.Component {
	render() {
		const {currentPage, link} = this.props

		let label = link.label
		let isNumber = false
		if (link.label.includes('Previous')) {
			label = '< Prev'
		}
		else if (link.label.includes('Next')) {
			label = 'Next >'
		}
		else if (link.label.includes('<<') || link.label.includes('>>')) {
			isNumber = false
		}
		else {
			isNumber = true
		}

		let page = null
		if (link.url) {
			const urlSplit = link.url.split('page=')
			page = urlSplit[1]
		}

		let showLink = true
		if (isNumber && (page < (currentPage - 1) || page > (currentPage + 2))) {
			showLink = false
		}
		if (label === '...') {
			showLink = false
		}

		let classes = Classnames([
			'pagination__link',
			{'disabled': !link.url},
			{'active': link.active},
			{'number': isNumber}
		])

		return (
			showLink && <button onClick={this.handleClick} className={classes}><span>{label}</span></button>
		)
	}

	handleClick = () => {
		const {link, handleClick} = this.props
		if (link.url) {
			const urlSplit = link.url.split('page=')
			handleClick(urlSplit[1])
		}
	}
}

export default PaginationLink

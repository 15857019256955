
// Libraries
import * as React from 'react'
import Classnames from 'classnames'

// Components
import AdminBackgroundTasksTableRow from '../../../components/admin/background-tasks/adminBackgroundTasksTableRow'

class AdminBroadcastsTable extends React.Component {
	render() {
		const {activeFilters, items, emptyMessage} = this.props

		let classNames = Classnames([
			'admin-table admin-table--background-tasks wrap--scrollx',
		])

		return (
			<div className={classNames}>
				<table className="admin-table__table">
					<thead>
						<tr>
							<th>Job</th>
							<th>Description</th>
							<th>Info</th>
							{!['complete', 'failed'].includes(activeFilters?.scope) && <th>Started At</th>}
							{['complete', 'failed'].includes(activeFilters?.scope) && <th>Finished At</th>}
							{['failed'].includes(activeFilters?.scope) && <th>Error</th>}
						</tr>
					</thead>
					<tbody>
					{
						items?.length
							? items.map((item) => <AdminBackgroundTasksTableRow
								activeFilters={activeFilters}
								key={item.id}
								item={item} />)
							: <tr><td colSpan={100} align="center">{emptyMessage}</td></tr>
					}
					</tbody>
				</table>
			</div>
		)
	}
}

export default AdminBroadcastsTable

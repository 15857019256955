
// Libraries
import * as React from 'react'

// Services
import BackgroundTasksService from '../../../services/backgroundTasks'

// Components
import PrivateRoute from '../../../components/privateRoute'
import LayoutAdmin from '../../../components/layoutAdmin'
import Seo from '../../../components/seo'
import AdminTableHeader from '../../../components/admin/adminTableHeader'
import AdminBackgroundTasksTable from '../../../components/admin/background-tasks/adminBackgroundTasksTable'
import Pagination from "../../../components/pagination"
import {faCogs} from '@fortawesome/pro-solid-svg-icons'

// Context
import NotificationContext from '../../../contexts/notification'
import qs from "qs";

class AdminBackgroundTasksIndexPage extends React.Component {
	state = {
		activeFilters: {
			'filter': {}
		},
		items: [],
		pagination: [],
		emptyMessage: 'Retrieving tasks data ...'
	}

	static contextType = NotificationContext

	render() {
		const {activeFilters, items, pagination, emptyMessage} = this.state

		return <PrivateRoute admin>
			<LayoutAdmin className="page--admin-table page--admin-table-background-tasks nav-blue-half">
				<Seo title="Background Tasks Queue" />
				<div className="admin-table__wrap">
					<AdminTableHeader
						titleText="Background Tasks" titleIcon={faCogs} />

					<p className='filters-status'>
						Filter by:
						<a href="/admin/background-tasks/" className={!['complete', 'failed'].includes(activeFilters?.scope) && 'active'}>In Progress</a>
						<a href="/admin/background-tasks/?scope=complete" className={activeFilters?.scope === 'complete' && 'active'}>Complete</a>
						<a href="/admin/background-tasks/?scope=failed" className={activeFilters?.scope === 'failed' && 'active'}>Failed</a>
					</p>

					<Pagination pagination={pagination} handlePaginationClick={this.handlePaginationClick} />

					<AdminBackgroundTasksTable
						activeFilters={activeFilters}
						getItems={this.getItems}
						items={items}
						emptyMessage={emptyMessage} />
				</div>
			</LayoutAdmin>
		</PrivateRoute>
	}

	componentDidMount() {
		const {activeFilters} = this.state
		const {addNotification} = this.context

		const searchParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })

		Promise.all([
			this.getItems({...activeFilters, ...searchParams}, false)
				.catch(() => addNotification('There was an error fetching the tasks.', 'error'))
		]).then(() => {
			this.setState({
				activeFilters: {...activeFilters, ...searchParams},
				emptyMessage: "No tasks found"
			})
		})
	}

	getItems = (queryParams = {}) => {
		const backgroundTasksService = new BackgroundTasksService()
		queryParams = {...this.state.activeFilters, ...queryParams}
		queryParams['paginate'] = 1
		return backgroundTasksService.list(queryParams).then( (response) => {
			this.setState({
				items: response.data.data,
				pagination: response.data,
				activeFilters: queryParams
			})
		}).catch(err => console.error(err))
	}

	handlePaginationClick = (page) => {
		const {activeFilters} = this.state
		const {addNotification} = this.context

		activeFilters['page'] = page

		this.setState({activeFilters}, () => {
			this.getItems(activeFilters)
				.catch(() => addNotification('There was an error filtering the tasks.', 'error'))
		})
	}
}

export default AdminBackgroundTasksIndexPage


// Libraries
import React from 'react'

// Services
import AuthService from '../services/auth'

// Components
import {Link} from 'gatsby'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faUser, faSignOut} from '@fortawesome/pro-regular-svg-icons'

const authService = new AuthService()

const navItems = [
	{
		name: 'My Profile',
		to: '/admin/profile',
		icon: faUser,
		active: true
	},
	{
		name: 'Log out',
		onClick: () => authService.logOut('/admin/login'),
		icon: faSignOut,
		active: true
	}
]

const SecondaryNavAdmin = () => (
	<div className="secondary-nav-admin">
		<ul className="secondary-nav-admin__menu">
			{navItems.map((navItem) =>
				navItem.active && <li>
					{navItem.onClick
						? <button onClick={navItem.onClick} title={navItem.name}>
							<FontAwesomeIcon icon={navItem.icon} title={`${navItem.name} icon`} /> {navItem.name}
						</button>
						: <Link to={navItem.to} title={navItem.name}>
							<FontAwesomeIcon icon={navItem.icon} title={`${navItem.name} icon`} /> {navItem.name}
						</Link>}
				</li>
			)}
		</ul>
	</div>
)

export default SecondaryNavAdmin
